@import "@/styles/globals.scss";
@font-face {
  font-family: 'MiaGrotesk';
  src: url('fonts/MiaGrotesk-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MiaGrotesk';
  src: url('fonts/MiaGrotesk-Light_Italic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'MiaGrotesk';
  src: url('fonts/MiaGrotesk-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MiaGrotesk';
  src: url('fonts/MiaGrotesk-Regular_Italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'MiaGrotesk';
  src: url('fonts/MiaGrotesk-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MiaGrotesk';
  src: url('fonts/MiaGrotesk-Bold_Italic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'MiaGrotesk';
  src: url('fonts/MiaGrotesk-Black.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MiaGrotesk';
  src: url('fonts/MiaGrotesk-Black_Italic.woff2') format('woff2');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
